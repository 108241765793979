import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll'

import 'alpinejs'
import "@fontsource/luckiest-guy/400.css"

import "@fontsource/kanit/300.css"
import "@fontsource/kanit/400.css"
import "@fontsource/kanit/600.css"

import 'tailwindcss/tailwind.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', function() {
  new SweetScroll();
})