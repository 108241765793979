import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, EffectFade])

export default class extends Controller {
  static targets = ['next', 'prev',  'container', 'pagination', 'mobileNext', 'mobilePrev']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      autoplay: {
        delay: 10000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: [this.nextTarget, this.mobileNextTarget],
        prevEl: [this.prevTarget, this.mobilePrevTarget]
      }
    })
  }
}
