import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['close']

  connect() {
    if (!(sessionStorage.getItem('checked'))) {
      setTimeout(() => {
        this.element.classList.add('transition-transform')
        this.element.classList.add('bar--active')
      }, 2000)
    }
  }

  close() {
    sessionStorage.setItem('checked', true)
    this.element.classList.remove('bar--active')
  }
}
