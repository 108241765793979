import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['question', 'answer', 'button']

  connect() {
    this.element.style.maxHeight = `${this.questionTarget.offsetHeight}px`

    setTimeout(() => {
      this.element.style.maxHeight = `${this.questionTarget.offsetHeight}px`
    }, 500);
  }

  toggleAnswer() {
    this.answerTarget.classList.toggle('active')
    this.buttonTarget.classList.toggle('rotate-45')

    if (this.answerTarget.classList.contains('active')) {
      this.element.style.maxHeight = `${
        this.questionTarget.offsetHeight + this.answerTarget.offsetHeight
      }px`
    } else {
      this.element.style.maxHeight = `${this.questionTarget.offsetHeight}px`
    }
  }
}
